html{
    scroll-behavior: smooth;
}

body{
    background-color: #0a192f;
}

.none{
    display: none;
}